<template>
  <div v-if="task" class="px-6 py-4 border-b border-gray-200 dark:border-gray-700">
    <!-- Title and Key Info -->
    <div style="display: flex; gap: 1%">
      <img v-if="task?.request?.url"
        :src="`https://www.google.com/s2/favicons?domain=${extractDomain(task.request.url)}`"
        :alt="extractDomain(task.request.url)" class="w-6 h-6" />
      <h1 class="text-xl font-semibold text-gray-900 dark:text-white mb-4">

        {{ task.request?.title || task.title || 'Untitled Test' }}
      </h1>
    </div>


    <!-- Info Grid -->
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
      <div>
        <p class="text-sm text-gray-500 dark:text-gray-400">Status</p>
        <div class="flex items-center gap-2">
          <span :class="statusClass">{{ task.status }}</span>
          <svg v-if="task.status === 'running'" width="24" height="24" viewBox="0 0 24 24" class="text-blue-500">
            <path class="text-gray-200"
              d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z" fill="currentColor" />
            <rect class="spinner-slow" x="11" y="6" rx="1" width="2" height="7" fill="currentColor" />
            <rect class="spinner-fast" x="11" y="11" rx="1" width="2" height="9" fill="currentColor" />
          </svg>
        </div>
      </div>
      <div>
        <p class="text-sm text-gray-500 dark:text-gray-400">Device</p>
        <p class="font-medium text-gray-900 dark:text-white">
          {{ task.device || 'Desktop Chrome' }}
        </p>
      </div>
      <div>
        <p class="text-sm text-gray-500 dark:text-gray-400">Date</p>
        <p class="font-medium text-gray-900 dark:text-white">
          {{ formatDate(task.created_at) }}
        </p>
      </div>
    </div>

    <!-- Navigation Goal -->
    <div v-if="task.request?.navigation_goal" class="border-t border-gray-200 dark:border-gray-700 pt-4">
      <p class="text-sm text-gray-500 dark:text-gray-400">Test Goal</p>
      <p class="font-medium text-gray-900 dark:text-white mt-1">
        {{ task.request.navigation_goal }}
      </p>
    </div>
  </div>
  <div v-else class="px-6 py-4 text-gray-500 dark:text-gray-400">
    Loading task details...
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps(['task'])

const formatDate = (dateString) => {
  return dateString ? new Date(dateString).toLocaleString() : ''
}

const statusClass = computed(() => ({
  'status-badge': true,
  'status-completed': props.task?.status === 'completed',
  'status-failed': props.task?.status === 'failed',
  'status-running': props.task?.status === 'running'
}))

const extractDomain = (url) => {
  try {
    return new URL(url).hostname
  } catch {
    return ''
  }
}
</script>


<style scoped>
.status-badge {
  @apply inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium transition-colors;
}

.status-completed {
  @apply bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200;
}

.status-failed {
  @apply bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200;
}

.status-running {
  @apply bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200;
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid #e5e7eb;
  border-top: 2px solid #3b82f6;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.spinner-slow {
  transform-origin: center;
  animation: spin 15s linear infinite;
}

.spinner-fast {
  transform-origin: center;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>