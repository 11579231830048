<template style="height: 100%;" class="h-full">

  <!-- <div v-if="!taskId?.startsWith('wr_') && (isLoading || isImagesLoading)"
    class="fixed inset-0 bg-black/30 backdrop-blur-sm z-50 flex items-center justify-center">
    <div class="bg-white dark:bg-gray-800 rounded-lg p-8 shadow-xl max-w-md w-full mx-4">
      <div class="space-y-6">
     
        <div class="flex justify-center">
          <svg class="animate-spin h-8 w-8 text-purple-600 dark:text-purple-500" xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
            </circle>
            <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
          </svg>
        </div>


        <div class="text-center">
          <p class="text-gray-600 dark:text-gray-300 font-medium">
            Loading Task Data
    
          </p>
        </div>
      </div>
    </div>
  </div> -->

  <div class="container mx-auto px-4 py-8">
    <div class="flex flex-col gap-8">
      <!-- Conditional Header - Show either TaskDetails or Current Action Info -->
      <div
        class="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700 overflow-hidden" >
        <div class="flex justify-between items-center px-6 py-4" style="width: 100%;">
          <TaskDetails :task="task" style="width: 100%;" />
          <button v-if="isTaskRunning" @click="cancelTask" :disabled="isCancelling"
            class="inline-flex items-center px-4 py-2 border border-red-300 rounded-md shadow-sm text-sm font-medium text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50">
            <svg v-if="isCancelling" class="animate-spin -ml-1 mr-2 h-4 w-4 text-red-700"
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
              </path>
            </svg>
            {{ isCancelling ? 'Cancelling...' : 'Cancel' }}
          </button>
        </div>
        <!-- <div v-else class="px-6 py-4">
          <div class="flex items-center justify-between">
            <div class="flex items-center gap-4">

              <div
                class="w-12 h-12 rounded-full bg-blue-500 flex items-center justify-center text-white text-xl font-bold">
                {{ selectedActionIndex + 1 }}
              </div>


              <div class="space-y-2">
                <div class="flex items-center gap-2">

                  <div class="flex items-center space-x-2">
                    <span class="action-type inline-flex items-center" :class="getActionTypeClass(selectedAction?.type)">
                      {{ selectedAction?.type }}
                    </span>
                    <span :class="[
                      'inline-flex items-center px-2 py-1 text-xs rounded-full',
                      selectedAction?.success
                        ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                        : 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200'
                    ]">
                      {{ selectedAction?.success ? 'Success' : 'Failed' }}
                    </span>
                  </div>
                </div>
                <p class="text-lg text-gray-700 dark:text-gray-300">
                  {{ selectedAction?.reasoning }}
                </p>
              </div>
            </div>


            <div class="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400">

              <div class="flex items-center gap-2">
                <span class="font-medium flex items-center">Action</span>
                <div class="px-2 py-1 bg-gray-100 dark:bg-gray-700 rounded flex items-center">
                  {{ selectedActionIndex + 1 }} / {{ actions.length }}
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>

      <!-- Add this near the top of your template, perhaps right after the title -->
      <!-- <div class="flex items-center justify-between mb-4">
        <h1 class="text-2xl font-semibold text-gray-900 dark:text-white">Test Details</h1>
      </div> -->

      <!-- Main Content Area -->
      <div class="flex gap-2" style="max-height: min(calc(100vh - 12rem), 900px);">
        <!-- Title with Favicon -->

        
        <!-- Rest of content -->
        <div class="w-2/3 rounded border border-gray-200 dark:border-gray-700 shadow-sm">
          <div class="w-full bg-white dark:bg-gray-800">
            <div v-if="activeTab === 'Actions'" class="h-full">
              <!-- Debug info -->
              <div v-if="!selectedAction?.screenshot" class="relative flex items-center justify-center"
                style="height: min(calc(100vh - 12rem), 900px)">
                <!-- Loading Spinner - Always visible -->
                <div class="flex flex-col items-center gap-4 transform-gpu scale-[3]">
                  <div class="w-8 h-8 border-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
                  <!-- <span class="text-sm text-gray-500 dark:text-gray-400">Loading image...</span> -->
                </div>
              </div>

              <!-- Image container with fixed height and scroll -->
              <div v-else class="overflow-auto" style="height: min(calc(100vh - 12rem), 900px); padding: 1.5%;">
                <img :src="selectedAction.screenshot" :alt="'Screenshot of ' + selectedAction.type"
                  class="w-full object-contain rounded-lg" />
              </div>
            </div>
            <div v-else-if="activeTab === 'Recording' && task?.recording_url">
              <video ref="videoRef"
                :src="`${BASE_URL}/artifact/recording?path=${encodeURIComponent(getRecordingPath(task.recording_url))}`"
                class="w-full h-full" controls>
              </video>
            </div>
            <div v-else-if="activeTab === 'Parameters'" class="h-full">
              <section class="space-y-8 rounded-lg bg-slate-elevation3 px-6 py-5">
                <div class="flex gap-16">
                  <div class="w-72">
                    <h1 class="text-lg">URL</h1>
                    <h2 class="text-base text-slate-400">The starting URL for the task</h2>
                  </div>
                  <input class="w-full" readonly :value="task?.request?.url" />
                </div>

                <div class="flex gap-16">
                  <div class="w-72">
                    <h1 class="text-lg">Navigation Goal</h1>
                    <h2 class="text-base text-slate-400">Where should Skyvern go and what should Skyvern do?</h2>
                  </div>
                  <textarea class="w-full" readonly :value="task?.request?.navigation_goal || ''"></textarea>
                </div>

                <div class="flex gap-16">
                  <div class="w-72">
                    <h1 class="text-lg">Navigation Payload</h1>
                    <h2 class="text-base text-slate-400">Specify important parameters, routes, or states</h2>
                  </div>
                  <pre
                    class="w-full bg-slate-900 rounded-lg p-4">{{ JSON.stringify(task?.request?.navigation_payload, null, 2) }}</pre>
                </div>

                <div class="flex gap-16">
                  <div class="w-72">
                    <h1 class="text-lg">Data Extraction Goal</h1>
                    <h2 class="text-base text-slate-400">What outputs are you looking to get?</h2>
                  </div>
                  <textarea class="w-full" readonly :value="task?.request?.data_extraction_goal || ''"></textarea>
                </div>

                <div class="flex gap-16">
                  <div class="w-72">
                    <div class="flex items-center gap-2">
                      <img 
                        v-if="task?.request?.url"
                        :src="`https://www.google.com/s2/favicons?domain=${extractDomain(task.request.url)}`"
                        :alt="extractDomain(task.request.url)"
                        class="w-4 h-4"
                      />
                      <h1 class="text-lg">Data Extraction Goal</h1>
                    </div>
                    <h2 class="text-base text-slate-400">What outputs are you looking to get?</h2>
                  </div>
                  <textarea class="w-full" readonly :value="task?.request?.data_extraction_goal || ''"></textarea>
                </div>

                <div class="flex gap-16">
                  <div class="w-72">
                    <h1 class="text-lg">Data Schema</h1>
                    <h2 class="text-base text-slate-400">Specify the output format in JSON</h2>
                  </div>
                  <pre
                    class="w-full bg-slate-900 rounded-lg p-4">{{ JSON.stringify(task?.request?.extracted_information_schema, null, 2) }}</pre>
                </div>
              </section>
            </div>
            <div v-else-if="activeTab === 'Diagnostics'" class="h-full">
              <div class="bg-slate-900 rounded-lg p-4 h-full overflow-auto">
                <pre
                  class="text-sm text-gray-100 font-mono whitespace-pre-wrap">{{ JSON.stringify(task?.diagnostics, null, 2) }}</pre>
              </div>
            </div>
          </div>
        </div>

        <!-- Right Side - Actions List -->
        <div
          class="flex flex-col w-1/3 rounded border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-900 overflow-hidden shadow-sm actions-list-container" 
          style="height: min(calc(100vh - 12rem), 900px)">
          <!-- Controls section stays fixed at top -->
          <div class="sticky top-0 z-10 backdrop-blur-sm bg-white/80 dark:bg-slate-900/80 border-b border-gray-200 dark:border-gray-700 shadow-sm">
            <div class="p-6 flex flex-col items-center gap-6">
              <!-- Top Section: Playback Controls -->
              <div class="flex items-center justify-center gap-8">
                <!-- Previous -->
                <div class="flex flex-col items-center">
                  <span class="text-xs font-medium text-gray-600 dark:text-gray-400 mb-2">Previous</span>
                  <button 
                    @click="handlePrevious"
                    class="p-3 rounded-full bg-white dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-300 transition-all duration-200 transform hover:scale-105 hover:shadow-md border border-gray-200 dark:border-gray-600"
                    :class="{ 'opacity-50 cursor-not-allowed': selectedActionIndex === 0 }"
                  >
                    <svg class="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                    </svg>
                  </button>
                </div>

                <!-- Play/Pause -->
                <div class="flex flex-col items-center">
                  <span class="text-xs font-medium text-gray-600 dark:text-gray-400 mb-2">
                    {{ isPlaying ? 'Pause' : 'Play' }}
                  </span>
                  <button 
                    @click="togglePlayback"
                    class="p-4 rounded-full bg-gradient-to-r from-blue-500 to-blue-600 dark:from-blue-600 dark:to-blue-700 hover:from-blue-600 hover:to-blue-700 dark:hover:from-blue-700 dark:hover:to-blue-800 text-white transition-all duration-200 transform hover:scale-105 hover:shadow-lg border border-blue-400 dark:border-blue-500"
                  >
                    <svg class="w-7 h-7" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path v-if="!isPlaying" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                        d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                      />
                      <path v-else stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                        d="M6 4h4v16H6zM14 4h4v16h-4z"
                      />
                    </svg>
                  </button>
                </div>

                <!-- Next -->
                <div class="flex flex-col items-center">
                  <span class="text-xs font-medium text-gray-600 dark:text-gray-400 mb-2">Next</span>
                  <button 
                    @click="handleNext"
                    class="p-3 rounded-full bg-white dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-300 transition-all duration-200 transform hover:scale-105 hover:shadow-md border border-gray-200 dark:border-gray-600"
                    :class="{ 'opacity-50 cursor-not-allowed': selectedActionIndex === actions.length - 1 }"
                  >
                    <svg class="w-6 h-6" viewBox="0 24 24" fill="none" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                  </button>
                </div>

                <!-- Speed Icon Button -->
                <div class="flex flex-col items-center">
                  <span class="text-xs font-medium text-gray-600 dark:text-gray-400 mb-2">Speed</span>
                  <button 
                    @click="isSpeedMenuOpen = !isSpeedMenuOpen"
                    class="p-3 rounded-full bg-white dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-300 transition-all duration-200 transform hover:scale-105 hover:shadow-md border border-gray-200 dark:border-gray-600"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                    </svg>
                  </button>
                </div>

                <!-- Live Indicator -->
                <div v-if="isTaskRunning" class="flex items-center gap-2 ml-6">
                  <div class="w-2 h-2 rounded-full bg-red-500 animate-pulse"></div>
                  <span class="text-xs font-medium text-gray-600 dark:text-gray-400">LIVE</span>
                </div>
              </div>

              <!-- Bottom Section: Speed Controls -->
              <div v-show="isSpeedMenuOpen" class="w-[300px] bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4 border border-gray-200 dark:border-gray-600">
                <!-- Your existing speed controls code -->
                <div class="flex flex-col space-y-2">
                  <!-- Speed Labels -->
                  <div class="flex justify-between text-xs text-gray-600 px-1">
                    <span>0.25x</span>
                    <span>Normal (1x)</span>
                    <span>2x</span>
                  </div>
                  <!-- Slider -->
                  <input 
                    type="range" 
                    v-model="playbackSpeed"
                    min="0.25" 
                    max="2" 
                    step="0.25"
                    class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                  />

                  <!-- Speed Display and Presets -->
                  <div class="flex justify-between items-center pt-1">
                    <span class="text-sm font-mono">{{playbackSpeed}}x</span>
                    <div class="flex gap-1">
                      <button 
                        v-for="speed in [0.25, 0.5, 1, 1.5, 2]" 
                        :key="speed"
                        @click="playbackSpeed = speed"
                        class="px-2 py-0.5 text-xs rounded-md border border-gray-200 hover:bg-gray-50"
                        :class="{'bg-blue-50 border-blue-200': playbackSpeed === speed}"
                      >
                        {{speed}}x
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Scrollable Actions List with strict height constraints -->
          <div class="actions-container flex-1 min-h-0 overflow-y-auto" ref="actionsContainer">
            <div class="p-4 space-y-4">
              <div v-for="(action, index) in actions" :key="action.stepId" :data-action-index="index"
                @click="selectAction(action, index)"
                class="flex cursor-pointer rounded-lg border-2 bg-gray-50 dark:bg-slate-800 p-4 relative" :class="{
                  'border-blue-500 ring-2 ring-blue-500': selectedActionIndex === index,
                  'border-transparent': selectedActionIndex !== index,
                  'border-l-red-500': !action.success,
                  'border-l-green-500': action.success
                }">

                <!-- Thumbnail -->
                <div class="w-16 h-16 flex-shrink-0 mr-4">
                  <img v-if="action.screenshot" :src="action.screenshot"
                    class="w-full h-full object-cover object-top rounded-lg"
                    @error="e => e.target.src = '/placeholder-action.png'" :alt="action.type || 'Action thumbnail'" />
                  <div v-else
                    class="w-full h-full bg-gray-100 dark:bg-gray-700 rounded-lg flex items-center justify-center">
                    <span class="material-icons text-gray-400">image</span>
                  </div>
                </div>

                <!-- Existing Action Content -->
                <div class="flex-1">
                  <div class="flex justify-between items-center"> <!-- Added items-center -->
                    <div class="flex items-center gap-2">
                      <span class="text-gray-700 dark:text-gray-300">#{{ index + 1 }}</span>
                    </div>
                    <div class="flex items-center gap-2">
                      <div v-if="action.type" class="inline-flex items-center px-2 py-1 text-xs rounded-full">
                        {{ action.type }}
                      </div>
                      <!-- :class="getActionTypeClass(action.type)" -->
                      <span class="inline-flex items-center px-2 py-1 text-xs rounded-full" :class="action.success
                        ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                        : 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200'">
                        {{ action.success ? 'Success' : 'Failed' }}
                      </span>
                    </div>
                  </div>
                  <div class="mt-2 text-xs text-gray-600 dark:text-slate-400">{{ action.reasoning }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, onUnmounted, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import { BASE_URL } from '../config'
import { swal } from '../utils/swal'
import TaskDetails from '../components/TaskDetails.vue'
import '../assets/completedTasks.css'

// Get taskId from route params first, then props
const route = useRoute()
const taskId = computed(() => props.taskId || route.params.taskId)
const workflowRunId = computed(() => taskId.value ? taskId.value.includes('wr_') : false)
const taskTitle = route.query.taskTitle

// Define props
const props = defineProps({
  taskId: {
    type: String,
    required: false
  }
})

// Core state
const task = ref(null)
const error = ref(null)
const isLoading = ref(false)
const activeTab = ref('Actions')
const videoRef = ref(null)
const tabs = ['Actions', 'Recording', 'Parameters', 'Diagnostics']
const selectedActionIndex = ref(null)
const selectedAction = ref(null)
const actionsContainer = ref(null)
const isImagesLoading = ref(true)
const imageBlobCache = ref({})
const loadedArtifacts = ref(0)
const totalArtifacts = ref(0)
const loadingProgress = ref(0)
const pollingInterval = ref(null)
const isPollingStarted = ref(false)
const processedStepIds = ref(new Set())
const isPlaying = ref(false)
const playbackSpeed = ref(0.25)
let playbackInterval = null
const actualTaskId = ref(null)
const lastProcessedActionId = ref(null)
const processedActionIds = ref(new Set())
const isSpeedMenuOpen = ref(false)

// Convert to computed property for better reactivity
const taskActions = ref([])
const actions = computed(() => {
  return taskActions.value.map(action => ({
    type: action.action_type,
    reasoning: action.reasoning,
    success: action.status === 'completed',
    screenshot: action.screenshot,
    stepId: action.step_id
  }))
})

// Utility functions
const getRecordingPath = (url) => {
  if (!url) return null
  return url.startsWith('file:///') ? url.slice(7) : url
}

const stopPolling = () => {
  if (pollingInterval.value) {
    clearInterval(pollingInterval.value)
    pollingInterval.value = null
  }
  isPollingStarted.value = false
}

const getActionTypeClass = (type) => {
  if (!type) return ''
  const actionType = type.toLowerCase()
  return {
    'action-type': true,
    'click': actionType.includes('click'),
    'input': actionType.includes('input'),
    'navigate': actionType.includes('navigate')
  }
}

// Fetch functions
const fetchStepArtifacts = async (stepId) => {
  const id = actualTaskId.value || taskId.value
  try {
    const response = await fetch(`${BASE_URL}/tasks/${id}/steps/${stepId}/artifacts`)
    if (!response.ok) throw new Error('Failed to fetch artifacts')
    const artifacts = await response.json()

    // Get action screenshots and sort them by their action_index
    const actionScreenshots = artifacts
      .filter(a => a.artifact_type === 'screenshot_action')
      .sort((a, b) => {
        const aIndex = parseInt(a.action_index || '0')
        const bIndex = parseInt(b.action_index || '0')
        return aIndex - bIndex
      })

    return actionScreenshots
  } catch (err) {
    console.error('Error fetching artifacts:', err)
    return []
  }
}


const preloadImage = async (path) => {
  if (!path) {
    loadedArtifacts.value++
    return null
  }

  try {
    if (imageBlobCache.value[path]) {
      loadedArtifacts.value++
      return imageBlobCache.value[path]
    }

    const cleanPath = path.startsWith('file:///') ? path.slice(7) : path
    const imageUrl = `${BASE_URL}/artifact/image?path=${encodeURIComponent(cleanPath)}`
    const response = await fetch(imageUrl)

    if (!response.ok) throw new Error(`Failed to load image: ${response.status}`)

    const blob = await response.blob()
    const blobUrl = URL.createObjectURL(blob)

    imageBlobCache.value = { ...imageBlobCache.value, [path]: blobUrl }
    loadedArtifacts.value++

    return blobUrl
  } catch (error) {
    loadedArtifacts.value++
    return null
  }
}

const processActions = async () => {
  if (!task.value?.steps || !taskActions.value) return

  try {
    const newActions = taskActions.value.filter(action => {
      if (processedActionIds.value.has(action.action_id)) {
        return false
      }
      if (!action.screenshot) {
        return true
      }
      return false
    })

    if (newActions.length === 0) return

    // Group actions by step
    const actionsByStep = newActions.reduce((acc, action) => {
      if (!acc[action.step_id]) {
        acc[action.step_id] = []
      }
      acc[action.step_id].push(action)
      return acc
    }, {})

    // Process each step's actions in order
    for (const [stepId, stepActions] of Object.entries(actionsByStep)) {
      const artifacts = await fetchStepArtifacts(stepId)

      // Match actions to artifacts based on their order in the step
      for (let i = 0; i < stepActions.length; i++) {
        const action = stepActions[i]
        const matchingScreenshot = artifacts[i]  // Match by position in the step

        if (matchingScreenshot?.uri) {
          const blobUrl = await preloadImage(matchingScreenshot.uri)
          const actionIndex = taskActions.value.findIndex(a => a.action_id === action.action_id)

          if (blobUrl && actionIndex !== -1) {
            taskActions.value[actionIndex] = {
              ...taskActions.value[actionIndex],
              screenshot: blobUrl
            }
            processedActionIds.value.add(action.action_id)
          }
        }
      }
    }

    // Handle action selection
    if (taskActions.value.length > 0) {
      if (task.value?.status === 'running') {
        const latestAction = taskActions.value[taskActions.value.length - 1]
        selectAction(latestAction, taskActions.value.length - 1)
      } else if (!selectedAction.value) {
        selectAction(taskActions.value[0], 0)
      }
    }

  } catch (error) {
    console.error('Error in processActions:', error)
  }
}


// Update loadNewArtifacts function
const loadNewArtifacts = async (artifacts) => {
  const batchSize = 3

  for (let i = 0; i < artifacts.length; i += batchSize) {
    const batch = artifacts.slice(i, i + batchSize)
    await Promise.all(batch.map(async artifact => {
      const result = await preloadImage(artifact.uri)
      return result
    }))
  }

}

const fetchTaskByWorkflowRunId = async () => {
  try {
    if (!workflowRunId.value) {
      throw new Error('No workflow run ID provided')
    }


    // Get all tasks for this workflow run
    const response = await fetch(`${BASE_URL}/tasks?workflow_run_id=${taskId.value}`)
    if (!response.ok) {
      throw new Error('Failed to fetch task by workflow run ID')
    }

    const tasks = await response.json()
    if (tasks.length === 0) {
      throw new Error('No tasks found for the provided workflow run ID')
    }

    // Find the most recent active task
    const sortedTasks = tasks
      .filter(task => task.status !== 'completed' && task.status !== 'failed')
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

    // If no active tasks, use the most recent task of any status
    const targetTask = sortedTasks.length > 0
      ? sortedTasks[0]
      : tasks.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0]

    actualTaskId.value = targetTask.task_id

    await fetchTaskData(actualTaskId.value)

    // Only start polling if task is not in a final state
    if (!['completed', 'failed', 'terminated', 'canceled'].includes(targetTask.status)) {
      startPolling()
    }
  } catch (err) {
    console.error('Error in fetchTaskByWorkflowRunId:', err)
    error.value = err.message
  } finally {
    isLoading.value = false
  }
}

const fetchTaskData = async (localTaskId) => {
  const id = localTaskId || actualTaskId.value || taskId.value
  try {
    if (!id) throw new Error('No task ID provided')

    const isInitialLoad = !task.value

    // Store existing action data
    const existingActions = new Map(
      taskActions.value.map(action => [action.action_id, action])
    )

    const [taskResponse, stepsResponse, actionsResponse] = await Promise.all([
      fetch(`${BASE_URL}/tasks/${id}`),
      fetch(`${BASE_URL}/tasks/${id}/steps`),
      fetch(`${BASE_URL}/tasks/${id}/actions`)
    ])

    if (!taskResponse.ok || !stepsResponse.ok || !actionsResponse.ok) {
      throw new Error('Failed to fetch task data')
    }

    const [taskData, stepsData, actionsData] = await Promise.all([
      taskResponse.json(),
      stepsResponse.json(),
      actionsResponse.json()
    ])

    // Preserve screenshots in the new actions data
    const updatedActionsData = actionsData.map(action => {
      const existing = existingActions.get(action.action_id)
      return existing ? { ...action, screenshot: existing.screenshot } : action
    })

    task.value = {
      ...taskData,
      request: {
        ...taskData.request,
        title: taskTitle || taskData.request?.title
      },
      steps: stepsData
    }

    taskActions.value = updatedActionsData

    if (isInitialLoad) {
      processedActionIds.value.clear()
    }

    await processActions()

    if (!['completed', 'failed', 'terminated'].includes(taskData.status)) {
      startPolling()
    } else {
      stopPolling()
    }

  } catch (err) {
    console.error('Error in fetchTaskData:', err)
    error.value = err.message
  } finally {
    isLoading.value = false
  }
}

// Action management
const selectAction = (action, index) => {
  selectedAction.value = action
  selectedActionIndex.value = index
  activeTab.value = 'Actions'
  scrollActionIntoView(index)
}

const scrollActionIntoView = (index) => {
  nextTick(() => {
    const actionElement = document.querySelector(`[data-action-index="${index}"]`)
    if (actionElement && actionsContainer.value) {
      const container = actionsContainer.value
      const containerRect = container.getBoundingClientRect()
      const elementRect = actionElement.getBoundingClientRect()
      const bottomThreshold = containerRect.height * 0.85

      if (elementRect.bottom > bottomThreshold + containerRect.top) {
        const buffer = 150
        const scrollAmount = elementRect.bottom - bottomThreshold + container.scrollTop - buffer
        container.scrollTo({
          top: scrollAmount,
          behavior: 'smooth'
        })
      }

      if (elementRect.top < containerRect.top) {
        const buffer = 100
        const scrollAmount = container.scrollTop - (containerRect.top - elementRect.top) - buffer
        container.scrollTo({
          top: scrollAmount,
          behavior: 'smooth'
        })
      }
    }
  })
}

const scrollToSelectedAction = () => {
  nextTick(() => {
    const container = document.querySelector('.actions-list-container')
    const selectedElement = document.querySelector(`[data-action-index="${selectedActionIndex.value}"]`)
    
    if (!container || !selectedElement) return
    
    const containerRect = container.getBoundingClientRect()
    const elementRect = selectedElement.getBoundingClientRect()
    
    // Check if element is out of view (either above or below)
    if (elementRect.bottom > containerRect.bottom || elementRect.top < containerRect.top) {
      selectedElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  })
}

const isTaskComplete = computed(() => {
  if (!task.value) return false
  return ['completed', 'failed', 'terminated', 'canceled'].includes(task.value.status)
})

const startPolling = () => {
  if (isTaskComplete.value) {
    return // Don't start polling for completed/failed/terminated/canceled tasks
  }

  if (!isPollingStarted.value) {
    isPollingStarted.value = true
    pollingInterval.value = setInterval(async () => {
      await fetchTaskData()
      // Stop polling if task reaches a final state
      if (isTaskComplete.value) {
        stopPolling()
      }
    }, 5000)
  }
}

// Playback controls
const togglePlayback = () => {
  isPlaying.value = !isPlaying.value
  
  if (isPlaying.value) {
    playbackInterval = setInterval(() => {
      if (selectedActionIndex.value < actions.value.length - 1) {
        selectedActionIndex.value++
        selectedAction.value = actions.value[selectedActionIndex.value]
        scrollToSelectedAction()
      } else {
        isPlaying.value = false
        clearInterval(playbackInterval)
      }
    }, 1000 / playbackSpeed.value)
  } else {
    clearInterval(playbackInterval)
  }
}

const handleNext = () => {
  if (selectedActionIndex.value < actions.value.length - 1) {
    selectedActionIndex.value++
    selectedAction.value = actions.value[selectedActionIndex.value]
    scrollToSelectedAction()
  }
}

const handlePrevious = () => {
  if (selectedActionIndex.value > 0) {
    selectedActionIndex.value--
    selectedAction.value = actions.value[selectedActionIndex.value]
    scrollToSelectedAction()
  }
}

// Clean up interval on component unmount
onUnmounted(() => {
  if (playbackInterval) {
    clearInterval(playbackInterval)
  }
})

// Update interval when speed changes
watch(playbackSpeed, (newSpeed) => {
  if (isPlaying.value) {
    clearInterval(playbackInterval)
    playbackInterval = setInterval(() => {
      if (selectedActionIndex.value < actions.value.length - 1) {
        selectedActionIndex.value++
        selectedAction.value = actions.value[selectedActionIndex.value]
        scrollToSelectedAction()
      } else {
        isPlaying.value = false
        clearInterval(playbackInterval)
      }
    }, 1000 / newSpeed)
  }
})

// Lifecycle hooks
onMounted(() => {
  if (workflowRunId.value) {
    setTimeout(() => {
      fetchTaskByWorkflowRunId()
    }, 5000)
  } else if (taskId.value) {
    fetchTaskData()
  }
})

// Clean up in onUnmounted
onUnmounted(() => {
  stopPolling()
  if (playbackInterval) {
    clearInterval(playbackInterval)
  }
  Object.values(imageBlobCache.value).forEach(url => {
    URL.revokeObjectURL(url)
  })
  imageBlobCache.value = {}
  processedActionIds.value.clear()
  processedStepIds.value.clear()
  actualTaskId.value = null
})

// Watchers
watch(() => taskId.value, (newId) => {
  if (newId) {
    if (workflowRunId.value) {
      fetchTaskByWorkflowRunId()
    } else {
      fetchTaskData(newId)
    }
  }
})

watch(() => workflowRunId.value, (newId) => {
  if (newId) {
    setTimeout(() => {
      fetchTaskByWorkflowRunId()
    }, 5000)
  } else {
    stopPolling()
  }
})

watch(playbackSpeed, () => {
  if (isPlaying.value) {
    stopPlayback()
    startPlayback()
  }
})

// Handle video playback
watch(() => task.value?.recording_url, () => {
  if (videoRef.value) {
    videoRef.value.load()
    if (videoRef.value.readyState >= 1) {
      videoRef.value.currentTime = 2
    } else {
      videoRef.value.addEventListener('loadedmetadata', () => {
        videoRef.value.currentTime = 2
      })
    }
  }
}, { immediate: true })

const isCancelling = ref(false)
const isTaskRunning = computed(() => task.value?.status === 'running')

const cancelTask = async () => {
  try {
    const result = await swal.confirm(
      'Cancel Task?',
      'Are you sure you want to cancel this task? This action cannot be undone.'
    )

    if (!result.isConfirmed) return

    await swal.success('Cancelled!', 'The task has been cancelled.')

    isCancelling.value = true

    const response = await fetch(`${BASE_URL}/tasksNew/cancel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ taskId: actualTaskId.value || taskId.value })
    })

    if (!response.ok) {
      throw new Error('Failed to cancel task')
    }

    // Update local task status
    task.value.status = 'cancelled'
  } catch (error) {
    console.error('Error cancelling task:', error)
  } finally {
    isCancelling.value = false
  }
}

const extractDomain = (url) => {
  try {
    return new URL(url).hostname;
  } catch {
    return '';
  }
};
</script>

<style scoped>
html, body {
  height: 100%;
  background-color: rgb(249 250 251); /* bg-gray-50 */
}

html.dark body {
  background-color: rgb(17 24 39); /* dark:bg-gray-900 */
}
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Slider Styles */
input[type="range"] {
  -webkit-appearance: none;
  @apply bg-gray-200;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #3b82f6;
  cursor: pointer;
  margin-top: -6px;
}

input[type="range"]::-moz-range-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #3b82f6;
  cursor: pointer;
  border: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  height: 4px;
  border-radius: 2px;
  background: #e5e7eb;
}

input[type="range"]::-moz-range-track {
  height: 4px;
  border-radius: 2px;
  background: #e5e7eb;
}
</style>